import React, { useRef, useState } from "react";
import ReCAPTCHA from 'react-google-recaptcha';
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import axios from 'axios';
import { Title, Button, Section, Box, Text, Input } from "../components/Core";

import PageWrapper from "../components/PageWrapper";
import { device } from "../utils";

const FormStyled = styled.form``;

const WidgetWrapper = styled(Box)`
  border-radius: 10px;
  background-color: #f7f7fb;
  padding-top: 80px;
  padding-bottom: 30px;
  padding-left: 50px;
  padding-right: 50px;
  @media ${device.lg} {
    padding-left: 140px;
    padding-right: 150px;
  }
  @media ${device.xl} {
    padding-left: 150px;
    padding-right: 150px;
  }
`;

const ContactUs = () => {
  const recaptchaRef = useRef(null);
  const [buttonText, setButtonText] = useState("Send Message");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [name, setName] = useState("");

  const onSubmit = (e) => {
    const recaptchaValue = recaptchaRef.current.getValue();
    if (recaptchaValue) {
      if (buttonText === "Send Message") {
        setButtonText("Sending...");

        var data = {
          email: email,
          subject: subject,
          message: message,
          name: name
        };

        axios({
          url: `https://members.hotsnail.com.au/api/Support/ContactEmail?recaptcha=${recaptchaValue}&webpage=HotSnail`,
          //url: `https://localhost:44330/api/Support/ContactEmail?recaptcha=${recaptchaValue}&webpage=HotSnail`,
          headers: { 'content-type': 'application/json' },
          data: data,
          method: 'POST'
        })
          .then((response) => {
            setButtonText("Sent...");
            setEmail("");
            setSubject("");
            setMessage("");
            setName("");

            setTimeout(
              () => {
                setButtonText('Send Message');
              },
              [3000]
            );
          })
          .catch((response) => {
            console.log('Error emailing support', response);
            setButtonText('ERROR...');
            setTimeout(
              () => {
                setButtonText('Send Message');
              },
              [3000]
            );
          });
      }
    } else {
      setButtonText('Error with catpcha');
      setTimeout(
        () => {
          setButtonText('Send Message');
        },
        [3000]
      );
    }
  };


  return (
    <>
      <PageWrapper footerDark>
        <Section>
          <Container>
            <Row className="justify-content-center">
              <Col lg={6} className="order-lg-1 pt-5 mt-4">
                <div>
                  <Title variant="hero">Contact us</Title>
                  <FormStyled
                    name="contact2"
                    method="post"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                  >
                    {/* You still need to add the hidden input with the form name to your JSX form */}
                    <input type="hidden" name="form-name" value="contact2" />
                    <Box mb={4}>
                      <Title
                        variant="card"
                        fontSize="18px"
                        as="label"
                        htmlFor="nameput"
                      >
                        Your name
                      </Title>
                      <Input
                        type="text"
                        placeholder="i.e. James Cameron"
                        id="nameput"
                        name="name"
                        value={name}
                        onChange={(e) => { console.log("test",e.target.value);  setName(e.target.value); return true; }}
                        required
                      />
                    </Box>
                    <Box mb={4}>
                      <Title
                        variant="card"
                        fontSize="18px"
                        as="label"
                        htmlFor="emailput"
                      >
                        Email address
                      </Title>
                      <Input
                        type="email"
                        placeholder="i.e. james@email.com"
                        id="emailput"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </Box>

                    <Box mb={4}>
                      <Title
                        variant="card"
                        fontSize="18px"
                        as="label"
                        htmlFor="messageput"
                      >
                        Message
                      </Title>
                      <Input
                        type="text"
                        as="textarea"
                        placeholder="Write your message here"
                        rows={4}
                        id="messageput"
                        name="message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        required
                      />
                    </Box>

                    <ReCAPTCHA
                      sitekey="6LcJfXkUAAAAAEDyjhJ2wSgQQmX9-q260IXuHKv1"
                      ref={recaptchaRef}
                    />

                    <Button
                      width="100%"
                      type="button"
                      style={{ marginTop: "20px" }}
                      borderRadius={10}
                      onClick={onSubmit}
                    >
                      {buttonText}
                    </Button>
                  </FormStyled>
                </div>
              </Col>
            </Row>
          </Container>
        </Section>
      </PageWrapper>
    </>
  );
};
export default ContactUs;
